<template>
  <div>
    <!-- Modal -->
    <div
      :id="id"
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
      @click="onClose"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div :id="id" class="modal-content px-1 py-2">
          <div class="modal-header p-4 pb-3 border-0 d-flex align-items-start">
            <h4 class="modal-title text-truncate">Country</h4>

            <button
              ref="closeModalButtonFChart"
              type="button"
              class="btn p-0 m-0 ps-2 pb-1"
              data-bs-dismiss="modal"
              @click="onModalClose"
            >
              <i class="pi pi-times"></i>
            </button>
          </div>
          <div class="border-top mx-4"></div>

          <div class="modal-body border-0 p-4 text-center">
            <SpecificCurrencyCountriesChart
              :key="renderKey"
              :data="countriesData"
              :is-full-chart="true"
              @clodeModal="onCloseEmit"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SpecificCurrencyCountriesChart from "./SpecificCurrencyCountriesChart.vue";
import { Modal } from "bootstrap";
import { onMounted, ref, watch } from "vue";

export default {
  components: { SpecificCurrencyCountriesChart },
  props: {
    countriesData: {
      type: Array,
      default: () => [],
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
  },
  emits: ["closeModal"],
  setup(props, ctx) {
    const myModal = ref(null);
    const renderKey = ref(0);
    const closeModalButtonFChart = ref();

    // watchers
    watch(
      () => props.showModal, // gette
      (showForm) => {
        renderKey.value += 1;
        if (showForm) {
          myModal.value.show();
        }
      }
    );

    // lifecycle hooks
    onMounted(() => {
      renderKey.value += 1;
      myModal.value = new Modal(document.getElementById(props.id), {
        // backdrop: "static",
        keyboard: false,
      });
      // myModal.value.show();
    });

    // methods
    function onModalClose() {
      ctx.emit("closeModal");
    }
    function onClose(event) {
      if (event.target.id === "currency-cuntries-chart") {
        ctx.emit("closeModal");
      }
    }
    function onCancel() {
      ctx.emit("closeModal");
    }
    function onCloseEmit() {
      closeModalButtonFChart.value.click();
    }

    return {
      myModal,
      onClose,
      onCancel,
      onModalClose,
      renderKey,
      onCloseEmit,
      closeModalButtonFChart,
    };
  },
};
</script>

<style scoped>
.modal-title {
  font-weight: 500;
  font-size: 1.85rem;
}
.modal-dialog {
  width: 880px;
  max-width: 880px;
}
.modal-content {
  border-radius: 20px;
  box-shadow: 0px 4px 40px 0px rgba(204, 213, 212, 0.3);
}
.btn {
  padding: 0.7rem 2.5rem;
}
.input-box {
  width: 47.5%;
}
</style>
