<template>
  <div>
    <BaseCardView title="Age" :loading="agesLoading" loading-height="36vh">
      <template #content>
        <SpecificCurrencyAgesChart :data="agesData" />
      </template>
    </BaseCardView>
  </div>
</template>

<script>
import SpecificCurrencyAgesChart from "./SpecificCurrencyAgesChart.vue";
import BaseCardView from "./helpers/BaseCardView.vue";
import { ref, computed, watch } from "vue";
import OverviewAPI from "@/services/api/OverviewAPI";

export default {
  components: { BaseCardView, SpecificCurrencyAgesChart },
  props: {
    fetchKey: {
      type: Number,
      default: 0,
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const agesData = ref([]);
    const agesLoading = ref(false);

    const filters = computed(() => {
      return props.filters;
    });

    const getAgesData = () => {
      agesData.value = [];
      agesLoading.value = true;

      OverviewAPI.getUsersAgesData(filters.value)
        .then((response) => {
          agesData.value = response.data?.demographics ?? [];
          agesLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            agesData.value = [];
            agesLoading.value = false;
            console.error("error: ", error);
          }
        });
    };

    const fetch = () => {
      getAgesData();
    };

    watch(
      () => props.fetchKey,
      () => {
        fetch();
      }
    );

    return {
      agesData,
      agesLoading,
    };
  },
};
</script>

<style lang="scss" scoped></style>
