<template>
  <div>
    <BaseCardView
      :loading="summaryDataLoading"
      title="Overview"
      loading-height="12vh"
    >
      <template #content>
        <div
          class="
            w-100
            d-flex
            flex-row flex-wrap
            justify-content-between
            align-items-center
            gap-4 gap-lg-0
          "
        >
          <BaseSummaryCard
            class="ov-summ-card"
            title="Customers"
            :amount="compactNumber(summaryData?.tenant_customers_count ?? 0, 0)"
            :is-trending-positively="customersCountDiff?.diffSign"
            :icon="require('@/assets/images/svg/components/Users.svg')"
            :is-clickable="true"
            @click="handleViewAllUsers"
          />

          <BaseSummaryCard
            class="ov-summ-card"
            title="Number of Txns"
            :amount="
              compactNumber(
                summaryData.value?.tenant_customers_transactions_count ?? 0,
                0
              )
            "
            :is-trending-positively="txCountDiff?.diffSign"
            :icon="require('@/assets/images/svg/components/Transactions.svg')"
            :is-clickable="true"
            @click="handleViewAllTxs"
          />

          <BaseSummaryCard
            class="ov-summ-card"
            title="Total Amount Spent"
            :amount="
              compactNumber(
                Math.abs(summaryData?.tenant_customers_total_spend ?? 0),
                2
              )
            "
            :is-trending-positively="totalSpendDiff?.diffSign"
            :icon="require('@/assets/images/svg/components/Fixed-amount.svg')"
          />

          <BaseSummaryCard
            class="ov-summ-card"
            title="Avg Spent / Day"
            :amount="
              compactNumber(
                Math.abs(summaryData?.tenant_customers_avg_daily_spend ?? 0),
                2
              )
            "
            :is-trending-positively="avgSpendDiff?.diffSign"
            :icon="require('@/assets/images/svg/components/Average.svg')"
          />
        </div>
      </template>
    </BaseCardView>
  </div>
</template>

<script>
import BaseCardTitle from "./helpers/BaseCardTitle.vue";
import BaseSummaryCard from "./helpers/BaseSummaryCard.vue";
import { ref, watch, computed, inject } from "vue";
import BaseCardView from "./helpers/BaseCardView.vue";
import OverviewAPI from "@/services/api/OverviewAPI";
import { useStore } from "vuex";
import { useDiffCalculator } from "@/composables/Helpers";
import { useRouter } from "vue-router";

export default {
  components: { BaseCardView, BaseSummaryCard, BaseCardTitle },
  props: {
    selectedCurrency: {
      type: String,
      default: "",
      required: true,
    },
    fetchKey: {
      type: Number,
      default: 0,
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const compactNumber = inject("compactNumber");
    const store = useStore();
    const router = useRouter();

    const summaryData = ref({});
    const summaryDataLoading = ref(false);

    const filters = computed(() => {
      return props.filters;
    });
    const currentCurrency = computed(() => {
      return props.selectedCurrency ?? null;
    });

    const customersCountDiff = computed(() => {
      return useDiffCalculator(
        summaryData.value?.tenant_customers_count,
        summaryData.value?.comparison_period_tenant_customers_count
      );
    });

    const totalSpendDiff = computed(() => {
      return useDiffCalculator(
        Math.abs(summaryData.value?.tenant_customers_total_spend ?? 0),
        Math.abs(
          summaryData.value?.comparison_period_tenant_customers_total_spend ?? 0
        )
      );
    });

    const avgSpendDiff = computed(() => {
      return useDiffCalculator(
        Math.abs(summaryData.value?.tenant_customers_avg_daily_spend ?? 0),
        Math.abs(
          summaryData.value
            ?.comparison_period_tenant_customers_avg_daily_spend ?? 0
        )
      );
    });

    const txCountDiff = computed(() => {
      return useDiffCalculator(
        Math.abs(summaryData.value?.tenant_customers_transactions_count ?? 0),
        Math.abs(
          summaryData.value
            ?.comparison_period_tenant_customers_transactions_count ?? 0
        )
      );
    });

    const getUserSummary = () => {
      summaryDataLoading.value = true;
      summaryData.value = {};

      OverviewAPI.getUserSummary(filters.value)
        .then((res) => {
          summaryData.value = res.data;
          summaryDataLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            summaryData.value = {};
            summaryDataLoading.value = false;
            console.error("error: ", error);
          }
        });
    };

    function fetch() {
      getUserSummary();
    }

    function handleViewAllTxs() {
      store.commit("updateShowFiltersBar", true);
      router.push({
        name: "MainTransactions",
        params: {
          filters: JSON.stringify({
            multi_currency: [
              { key: currentCurrency.value, value: currentCurrency.value },
            ],
          }),
        },
      });
    }

    function handleViewAllUsers() {
      store.commit("updateShowFiltersBar", true);
      router.push({
        name: "UsersView",
        params: {
          filters: JSON.stringify({
            multi_currency: [
              { key: currentCurrency.value, value: currentCurrency.value },
            ],
          }),
        },
      });
    }

    watch(
      () => props.fetchKey,
      () => {
        fetch();
      }
    );

    watch(summaryData, (current) => {
      store.commit("updateSelectedCurrencyOverview", {
        ...current,
        customersCountDiff: { ...customersCountDiff.value },
        totalSpendDiff: { ...totalSpendDiff.value },
        avgSpendDiff: { ...avgSpendDiff.value },
      });
    });

    return {
      summaryData,
      summaryDataLoading,
      compactNumber,
      customersCountDiff,
      totalSpendDiff,
      avgSpendDiff,
      txCountDiff,
      handleViewAllTxs,
      handleViewAllUsers,
    };
  },
};
</script>

<style lang="scss" scoped>
.ov-summ-card {
  width: fit-content;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
@media screen and (max-width: 890px) {
  .ov-summ-card {
    width: 45%;
  }
}
@media screen and (max-width: 750px) {
  .ov-summ-card {
    width: 100%;
  }
}
</style>
