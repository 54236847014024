<template>
  <div>
    <div v-if="!loading">
      <h6>{{ text }}</h6>
    </div>
    <div v-else>
      <h6 class="opacity-50">Loading...</h6>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import OverviewAPI from "@/services/api/OverviewAPI";

import moment from "moment";

export default {
  props: {
    fetchKey: {
      type: Number,
      default: 0,
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const data = ref({});
    const loading = ref(false);

    const filters = computed(() => {
      return props.filters;
    });

    const formatDate = (date) => {
      return moment(date).format("D MMM, YYYY");
    };

    const getMinMaxdates = () => {
      loading.value = true;
      data.value = {};

      OverviewAPI.getMinMaxdates(filters.value)
        .then((response) => {
          data.value = response?.data;
          loading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            data.value;
            loading.value = false;
            console.error("error: ", error);
          }
        });
    };

    const fetch = () => {
      getMinMaxdates();
    };

    const text = computed(() => {
      const minDate = data.value?.min_tenant_transaction_date;
      const maxDate = data.value?.max_tenant_transaction_date;

      if (minDate && maxDate)
        return `Showing results from ${formatDate(minDate)} to ${formatDate(
          maxDate
        )}`;

      return "No data results for the selected time range";
    });

    watch(
      () => props.fetchKey,
      () => {
        fetch();
      }
    );

    return {
      loading,
      text,
    };
  },
};
</script>

<style lang="scss" scoped></style>
