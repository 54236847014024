<template>
  <div>
    <BaseCardView
      title="Country"
      :loading="countriesLoading"
      loading-height="37vh"
    >
      <template #content>
        <SpecificCurrencyCountriesChart
          :data="countriesData"
          @onReport="onReport"
        />
      </template>
    </BaseCardView>

    <!--Full Screen Chart-->
    <SpecificCurrencyCountriesFullChart
      id="currency-cuntries-chart"
      :countries-data="countriesData"
      :show-modal="showReportForm"
      @closeModal="closeModal"
    />
    <!--Full Screen Chart-->
  </div>
</template>

<script>
import SpecificCurrencyCountriesFullChart from "./SpecificCurrencyCountriesFullChart.vue";
import SpecificCurrencyCountriesChart from "./SpecificCurrencyCountriesChart.vue";
import DemographicsAPI from "@/services/api/DemographicsAPI";
import BaseCardView from "./helpers/BaseCardView.vue";
import { ref, computed, watch } from "vue";

export default {
  components: {
    BaseCardView,
    SpecificCurrencyCountriesChart,
    SpecificCurrencyCountriesFullChart,
  },
  props: {
    fetchKey: {
      type: Number,
      default: 0,
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const countriesData = ref([]);
    const countriesLoading = ref(false);
    const showReportForm = ref(false);

    const filters = computed(() => {
      return props.filters;
    });

    const getCountriesData = () => {
      countriesLoading.value = true;
      countriesData.value = [];

      DemographicsAPI.getUsersCountriesData(filters.value)
        .then((response) => {
          countriesData.value = response.data?.demographics ?? [];
          countriesLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            countriesData.value = [];
            countriesLoading.value = false;
            console.error("error: ", error);
          }
        });
    };

    const fetch = () => {
      getCountriesData();
    };

    const closeModal = () => {
      showReportForm.value = false;
    };
    const onReport = () => {
      if (showReportForm.value) {
        showReportForm.value = false;
        return;
      }
      showReportForm.value = true;
    };
    watch(
      () => props.fetchKey,
      () => {
        fetch();
      }
    );

    return {
      countriesData,
      countriesLoading,
      showReportForm,
      closeModal,
      onReport,
    };
  },
};
</script>

<style lang="scss" scoped></style>
