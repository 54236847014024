<template>
  <div>
    <BaseCardView
      :loading="spendChartLoading"
      title="Spending"
      loading-height="35vh"
    >
      <template #content>
        <CustomersSpendChart :data="spendChartData" :show-top-date="true" />
      </template>
    </BaseCardView>
  </div>
</template>

<script>
import OverviewAPI from "@/services/api/OverviewAPI";
import CustomersSpendChart from "./CustomersSpendChart.vue";
import BaseCardView from "./helpers/BaseCardView.vue";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  components: { BaseCardView, CustomersSpendChart },
  props: {
    selectedCurrency: {
      type: String,
      default: "",
      required: true,
    },
    fetchKey: {
      type: Number,
      default: 0,
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const store = useStore();
    const spendChartData = ref({});
    const spendChartLoading = ref(false);

    const filters = computed(() => {
      return props.filters;
    });

    const timeFilters = computed(() => store.state.overViewStore.timeFilters);

    const getSpendChartData = () => {
      spendChartLoading.value = true;
      spendChartData.value = [];

      OverviewAPI.getSpendChartData(
        filters.value,
        timeFilters.value?.periodFilter ?? 30
      )
        .then((res) => {
          spendChartData.value = [...(res.data?.tenant_customers_spends ?? [])];
          spendChartLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            spendChartData.value = [];
            spendChartLoading.value = false;
            console.error("error: ", error);
          }
        });
    };

    const fetch = () => {
      getSpendChartData();
    };

    watch(
      () => props.fetchKey,
      () => {
        fetch();
      }
    );

    return { spendChartData, spendChartLoading };
  },
};
</script>

<style lang="scss" scoped></style>
