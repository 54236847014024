<template>
  <div class="position-relative">
    <div v-if="!isChartEmpty" class="d-flex flex-column w-100">
      <div class="flex-grow-1">
        <BaseDoughnutChart
          :data="chartData"
          :prop-options="chartOptions"
          height="35vh"
        />
      </div>

      <!--Labels-->
      <div class="d-flex flex-column gap-3 labels-container">
        <BaseBoxLabel label="Female" color="#335855" />
        <BaseBoxLabel label="Male" color="#C8E1DF" />
      </div>

      <!--Labels-->
    </div>

    <BaseEmptyState v-else min-height="35vh" />
  </div>
</template>
<script>
import BaseBoxLabel from "./helpers/BaseBoxLabel.vue";
import { computed, inject } from "vue";
import BaseDoughnutChart from "@/components/charts/BaseDoughnutChart.vue";
import BaseEmptyState from "@/components/helpers/BaseEmptyState.vue";
import { currencyGenderTooltip } from "../composables/ChartTooltips";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  components: { BaseDoughnutChart, BaseEmptyState, BaseBoxLabel },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const capitalizeFirstLetter = inject("capitalizeFirstLetter");

    //== Computed ==
    const femaleData = computed(() => {
      let femaleObj = (props.data ?? []).find(
        (obj) => Object.keys(obj)?.[0] === "female"
      );

      if (femaleObj) femaleObj = femaleObj["female"];

      return femaleObj;
    });

    const maleData = computed(() => {
      let maleObj = (props.data ?? []).find(
        (obj) => Object.keys(obj)?.[0] === "male"
      );

      if (maleObj) maleObj = maleObj["male"];

      return maleObj;
    });

    const chartData = computed(() => ({
      datasets: [
        {
          data: [
            {
              value: Math.abs(femaleData.value?.tenant_customers_count ?? 0),
              label: "female",
              name: "Females",
            },
            {
              value: Math.abs(maleData.value?.tenant_customers_count ?? 0),
              label: "male",
              name: "Males",
            },
          ],
          backgroundColor: ["#335855", "#C8E1DF"],
          borderWidth: 0,
        },
      ],
    }));

    const isChartEmpty = computed(
      () =>
        (femaleData.value?.tenant_customers_count ?? 0) === 0 &&
        (maleData.value?.tenant_customers_count ?? 0) === 0
    );

    const customToolTip = currencyGenderTooltip(
      chartData.value,
      (clickedItem) => {
        if (clickedItem?.name && clickedItem?.name !== "--") {
          store.commit("updateShowFiltersBar", true);

          router.push({
            name: "MainTransactions",
            params: {
              filters: JSON.stringify({
                gender: [
                  {
                    key: clickedItem?.label,
                    value: capitalizeFirstLetter(clickedItem?.label),
                  },
                ],
                multi_currency: [
                  {
                    key: route.params?.currencyId,
                    value: route.params?.currencyId,
                  },
                ],
              }),
            },
          });
        }
      }
    );

    const chartOptions = computed(() => ({
      borderRadius: 0,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          external: customToolTip,
        },
        //Custom Datalabel configs --> Disable
        datalabels: {
          formatter: () => {
            return null;
          },
        },
      },
      cutout: "70%",
      radius: "77%",
    }));
    //== Computed ==

    return {
      isChartEmpty,
      chartData,
      chartOptions,
      customToolTip,
    };
  },
};
</script>
<style scoped>
.labels-container {
  padding: 0.5rem;
  padding-bottom: 1rem;
  position: absolute;
  bottom: 0;
}
</style>
