<template>
  <div class="h-100 pb-4 px-4 hide-scrollbar">
    <!--Date Range-->
    <div class="py-4">
      <SpecificCurrencyViewInfoText :fetch-key="fetchKey" :filters="filters" />
    </div>
    <!--Date Range-->

    <!--Overview-->
    <SpecificCurrencyOverviewBar
      :selected-currency="currencyId"
      :fetch-key="fetchKey"
      :filters="filters"
    />
    <!--Overview-->

    <div
      class="
        pt-4
        mt-4
        mb-4
        w-100
        h-100
        d-flex
        flex-wrap
        justify-content-between
      "
    >
      <!--Spending-->
      <SpecificCurrencySpendingChartFull
        class="col-12 pb-4"
        :selected-currency="currencyId"
        :fetch-key="fetchKey"
        :filters="filters"
      />
      <!--Spending-->

      <!--Transactions-->
      <SpecificCurrencyTxs
        :fetch-key="fetchKey"
        :filters="filters"
        :selected-currency="currencyId"
        class="col-12 col-lg-6 p-4 px-0 pe-lg-4"
      />
      <!--Transactions-->

      <div
        class="col-12 col-lg-6 p-4 pt-0 pe-0 ps-0 ps-lg-4 d-flex flex-column"
      >
        <div
          class="d-flex justify-content-between align-items-center flex-nowrap"
        >
          <!--Title-->
          <BaseCardTitle class="pt-4 pb-3 w-100" title="Customers" />
          <!--Title-->

          <!--Users Count Title-->
          <SpecificCurrencyUsersCountTitle :selected-currency="currencyId" />
          <!--Users Count Title-->
        </div>

        <!--Country-->
        <SpecificCurrencyCountries
          :fetch-key="fetchKey"
          :filters="filters"
          class="py-4 pb-4"
        />
        <!--Country-->

        <!--Gender-->
        <SpecificCurrencyGenders
          :fetch-key="fetchKey"
          :filters="filters"
          class="py-4 pb-4"
        />
        <!--Gender-->

        <!--Age-->
        <SpecificCurrencyAges
          :fetch-key="fetchKey"
          :filters="filters"
          class="pt-4 mb-5"
        />
        <!--Age-->
      </div>
    </div>
  </div>
</template>

<script>
import SpecificCurrencyViewInfoText from "../components/SpecificCurrencyViewInfoText.vue";
import SpecificCurrencyUsersCountTitle from "../components/SpecificCurrencyUsersCountTitle.vue";
import SpecificCurrencyAges from "../components/SpecificCurrencyAges.vue";
import SpecificCurrencyGenders from "../components/SpecificCurrencyGenders.vue";
import SpecificCurrencyCountries from "../components/SpecificCurrencyCountries.vue";
import SpecificCurrencyTxs from "../components/SpecificCurrencyTxs.vue";
import SpecificCurrencySpendingChartFull from "../components/SpecificCurrencySpendingChartFull.vue";
import SpecificCurrencyOverviewBar from "../components/SpecificCurrencyOverviewBar.vue";
import BaseCardTitle from "../components/helpers/BaseCardTitle.vue";
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    BaseCardTitle,
    SpecificCurrencyOverviewBar,
    SpecificCurrencySpendingChartFull,
    SpecificCurrencyTxs,
    SpecificCurrencyCountries,
    SpecificCurrencyGenders,
    SpecificCurrencyAges,
    SpecificCurrencyUsersCountTitle,
    SpecificCurrencyViewInfoText,
  },
  props: {
    currencyId: {
      type: String,
      default: "",
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const fetchKey = ref(0);

    // == Computed ==
    const filters = computed(() => {
      return {
        ...store.getters.filters,
        ...(props.currencyId && {
          tenant_transaction_currency_codes: [props.currencyId],
        }),
      };
    });
    // == Computed ==

    // == Functions ==
    const fetchData = () => {
      //[1] Re-fetch event will be raised
      fetchKey.value += 1;

      //[2] Abort all previous pending requests
      store.dispatch("CANCEL_PENDING_REQUESTS");
    };
    // == Functions ==

    // == Hooks ==
    onMounted(() => {
      //fetch on mount
      fetchData();
    });

    onUnmounted(() => {
      store.dispatch("CANCEL_PENDING_REQUESTS");
    });
    // == Hooks ==

    // == Watchrs ==
    watch(filters, (currFilters, prevFilters) => {
      if (prevFilters?.tenant_transaction_from_date) {
        fetchData();
      }
    });
    // == Watchrs ==

    return {
      fetchKey,
      filters,
    };
  },
};
</script>

<style lang="scss" scoped></style>
