<template>
  <BaseSmallAmountTitle
    :show-currency="true"
    :show-trend-sign="true"
    :amount="
      compactNumber(
        Math.abs(selectedCurrencyOverview?.tenant_customers_total_spend ?? 0),
        2
      )
    "
    font-size="1.22rem"
    :is-trending-positively="selectedCurrencyOverview?.totalSpendDiff?.diffSign"
    @click="null"
  />
</template>

<script>
import { useStore } from "vuex";
import BaseSmallAmountTitle from "./helpers/BaseSmallAmountTitle.vue";
import { computed, inject } from "vue";

export default {
  components: { BaseSmallAmountTitle },
  setup() {
    const compactNumber = inject("compactNumber");
    const store = useStore();

    const selectedCurrencyOverview = computed(
      () => store.state.allCurrenciesStore.selectedCurrencyOverview
    );

    return {
      compactNumber,
      selectedCurrencyOverview,
    };
  },
};
</script>

<style lang="scss" scoped></style>
